import {Track, TrackSubtitle} from "./types";
import {ReactElement} from "react";
import {StyledBoxColumn, StyledBoxRow} from "@/shared/StyledComponents";
import {PAGE_FRAGMENT_HALF_WIDTH, PD_MD, PD_XSM} from "@/shared/dimens";
import {Button, Typography, useTheme} from "@mui/material";
import {TrackImage} from "./TrackImage";

export function SelectTrack(props: {
  tracks: Track[],
  onTrackSelected: (track: Track, index: number) => void,
  selectedIndex?: number,
}): ReactElement {
  const theme = useTheme();
  return <StyledBoxColumn style={{width: "100%", minWidth: PAGE_FRAGMENT_HALF_WIDTH}}>
    <Typography variant="h6" style={{padding: PD_MD}}>Tracks</Typography>
    {props.tracks.map((track, index) => <Button
      style={{padding: 0}}
      color={index === props.selectedIndex ? "secondary" : "primary"}
      onClick={() => props.onTrackSelected(track, index)}
      variant="outlined">
      <StyledBoxRow style={{boxSizing: "border-box", width: "100%", height: 72, padding: PD_XSM, alignItems: "center"}}>
        <Typography style={{fontSize: "200%", color: theme.palette.secondary.main, width: 72}}>{index + 1}</Typography>
        <TrackImage track={track} style={{width: 56, height: 56}}/>
        <StyledBoxColumn style={{flexGrow: 1, gap: 0}}>
          <Typography style={{textAlign: "start", fontWeight: "bold"}}>{track.title}</Typography>
          <Typography variant="body2" style={{textAlign: "start",}}>{TrackSubtitle(track)}</Typography>
        </StyledBoxColumn>
      </StyledBoxRow>
    </Button>)}
  </StyledBoxColumn>
}