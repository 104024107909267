import {AppConfig, BaseAppProps, BaseAppState} from "shared/BaseApp";
import React, {ReactElement} from 'react';
import {createTheme, Theme, ThemeOptions} from "@mui/material";
import {DEFAULT_THEME_OPTIONS} from "../shared/colors";
import {Main} from "./Main";
import {FIREBASE_CONFIG} from "../consts";
import {BORDER_RADIUS} from "../shared/dimens";
import {ProvisionedApp} from "../shared/ProvisionedApp";
import {ProvisionedAppConfig} from "../shared/types";
import "../res/css/App.css";
import {JSON_OBJECT} from "../shared/json/helpers";
import {BOLT_EMBED_THEME_OPTIONS, EmbedConfig, EmbedThemeOptions, EmbedType} from "../jambam-shared/types";
import {PathComponent} from "../index";

export type AppProps = BaseAppProps & {}

type AppState = BaseAppState & {}

export function createEmbedTheme(options: EmbedThemeOptions): Theme {
  const themeOptions: ThemeOptions = {
    ...DEFAULT_THEME_OPTIONS,
    components: {
      ...DEFAULT_THEME_OPTIONS.components,
      MuiTab: {
        styleOverrides: {
          root: {
            fontWeight: "bold",
            fontSize: "75%",
            borderRadius: BORDER_RADIUS,
          },
        }
      },
    },
    typography: {
      h1: {
        fontWeight: "900",
        fontFamily: "InterTight, sans-serif",
        lineHeight: 1,
      },
      h2: {
        fontWeight: "900",
        fontFamily: "InterTight, sans-serif",
        lineHeight: 1,
      },
      h3: {
        fontWeight: "900",
        fontFamily: "InterTight, sans-serif",
        lineHeight: 1,
      },
      h4: {
        fontWeight: "900",
        fontFamily: "InterTight, sans-serif",
        lineHeight: 1,
      },
      h5: {
        fontWeight: "900",
        fontFamily: "InterTight, sans-serif",
        lineHeight: 1,
      },
      h6: {
        fontWeight: "900",
        fontFamily: "InterTight, sans-serif",
        lineHeight: 1,
      },
      fontSize: 13,
    },
    palette: {
      mode: options.mode,
      background: {
        default: options.bg1,
        paper: options.bg2,
      },
      primary: {
        main: options.primary,
      },
      secondary: {
        main: options.secondary,
      },
    }
  }
  return createTheme(themeOptions);
}

const DEFAULT_EMBED_CONFIG: EmbedConfig = {
  type: EmbedType.NONE,
  themeOptions: BOLT_EMBED_THEME_OPTIONS,
}

export class AppRefs {

  constructor(readonly audio: HTMLAudioElement) {
  }
}

export class App extends ProvisionedApp<AppProps, AppState> {

  private static appRefs: AppRefs;

  private embedConfig: EmbedConfig;

  constructor(props: AppProps, context: any) {
    super(props, context);
    this.embedConfig = App.readEmbedConfig();
  }

  static getAppRefs(): AppRefs {
    if (!App.appRefs) {
      App.appRefs = App.createAppRefs();
    }
    return App.appRefs;
  }

  private static createAppRefs() {
    return new AppRefs(document.getElementById("player") as HTMLAudioElement);
  }

  getProvisionedAppId(): string {
    return "jambam";
  }

  getProvisionedAppConfig(): ProvisionedAppConfig | null {
    return null;
  }

  onCreateAppConfig(): AppConfig {
    // We must read config here (in addition to setting it as member var in constructor) since "this" object isn't fully
    // constructed yet.
    const embedConfig = App.readEmbedConfig();
    return {
      name: "JAMBAM",
      icon: "/icon.png",
      logo: "/logo192.png",
      stamp: "/stamp.png",
      defaultUserImage: "/images/default_user.png",
      loginConfig: {
        noLogin: true,
      },
      theme: createEmbedTheme((embedConfig || DEFAULT_EMBED_CONFIG).themeOptions),
      firebaseConfig: {options: FIREBASE_CONFIG},
    };
  }

  private static readEmbedConfig(): EmbedConfig | null {
    const search = window.location.search;
    if (search) {
      const params = new URLSearchParams(search.substring(1));
      const ecfg = params.get("ecfg") as string;
      if (ecfg) {
        return JSON_OBJECT.deserializeObject(JSON.parse(atob(ecfg)), EmbedConfig);
      }
    }
    return null;
  }

  static nestedPaths(): PathComponent[] {
    return [];
  }

  protected renderMain(): ReactElement {
    return <Main path={this.props.path} embedConfig={this.embedConfig}/>;
  }
}
