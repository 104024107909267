import React from "react";
import {App} from "./App";
import {EmbedConfig, Playlists, Queue} from "../jambam-shared/types";
import {BaseFragment, BaseFragmentProps, BaseFragmentState} from "../shared/BaseFragment";
import {AudioPlayer, AudioPlayerDisplay} from "../jambam-shared/AudioPlayer";

export type MainProps = BaseFragmentProps & {
  embedConfig: EmbedConfig,
}

type MainState = BaseFragmentState & {}

export class Main extends BaseFragment<MainProps, MainState> {

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    const memberId = this.props.embedConfig?.memberId;
    const playlistId = this.props.embedConfig?.playlistId;
    if (memberId && playlistId) {
      const playlist = await Playlists.getMemberInstance(this.props.embedConfig.memberId).getOrLoadItem(playlistId);
      const queue = Queue.getInstance();
      queue.setTracks(playlist.getTracks());
    }
  }

  renderContainerContent(): React.ReactElement {
    return <AudioPlayer audio={App.getAppRefs().audio} initialDisplay={AudioPlayerDisplay.EMBED}/>;
  }
}
