import {BaseListItem, BaseListItemsLoader, ListKeysLoader, UserDisplayName} from "@/shared/types";
import {getMemberAuth} from "@/shared/auth";
import {JsonObject} from "@/shared/json/json-object";
import {JsonProperty} from "@/shared/json/json-property";
import {JSON_OBJECT} from "@/shared/json/helpers";
import {Rel, SystemKeys} from "@/shared/database";
import {md5_uuid} from "@/shared/md5";
import {IAudioMetadata} from "music-metadata";
import {PaletteMode} from "@mui/material";

@JsonObject()
export class Follow extends BaseListItem {

  static createNew(followId: string) {
    return new Follow(getMemberAuth().getMemberId(), Date.now(), followId);
  }

  constructor(creator: string, created: number, followId: string) {
    super(followId, creator, created);
  }

  static readonly FOLLOWERS_REL_NAME = "followers";

  followersRel() {
    return new Rel(Follow.FOLLOWERS_REL_NAME, this.id, this.creator, true);
  }
}

export class Follows extends BaseListItemsLoader<Follow> {

  private static instance: Follows;
  private static readonly memberInstances = new Map<string, Follows>();

  static getInstance(): Follows {
    if (!this.instance) {
      this.instance = new Follows();
    }
    return this.instance;
  }

  static getMemberInstance(memberId: string): Follows {
    let instance = this.memberInstances.get(memberId);
    if (!instance) {
      instance = new Follows(memberId);
      this.memberInstances.set(memberId, instance);
    }
    return instance;
  }

  constructor(private readonly memberId?: string) {
    super({shared: Boolean(memberId)});
  }

  protected basePath(): string {
    return "follows" + (this.memberId ? "/" + this.memberId : "");
  }

  protected deserializeItem(value: any): Follow {
    return JSON_OBJECT.deserializeObject(value, Follow);
  }

  protected serializeItem(item: Follow): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: Follow, item2: Follow): number {
    return 0;
  }

  async addFollow(follow: Follow) {
    await this.addListItem(follow, follow.followersRel());
  }

  async deleteFollow(follow: Follow) {
    await this.deleteListItem(follow, follow.followersRel());
  }
}

export class Followers extends ListKeysLoader {

  private static instance: Followers;
  private static readonly memberInstances = new Map<string, Followers>();

  static getInstance(): Followers {
    if (!this.instance) {
      this.instance = new Followers();
    }
    return this.instance;
  }

  static getMemberInstance(memberId: string): Followers {
    let instance = this.memberInstances.get(memberId);
    if (!instance) {
      instance = new Followers(memberId);
      this.memberInstances.set(memberId, instance);
    }
    return instance;
  }

  constructor(private readonly memberId?: string) {
    super({shared: Boolean(memberId)});
  }

  protected basePath(): string {
    return "follows/" + SystemKeys.REL + "/followers" + (this.memberId ? "/" + this.memberId : "");
  }
}

@JsonObject()
export class ShareScope {

  @JsonProperty()
  memberIds: string[]

  constructor() {
  }
}

export enum Privacy {
  AUTO = "auto", // Anyone with direct link can access, but not shown on profile page. Similar to "anyone with a link".
  MEMBERS = "members", // Anyone currently logged in.
  SCOPED = "scoped", // Anyone in share scope.
  PRIVATE = "private",
  PUBLIC = "public",
}

export abstract class BaseJam extends BaseListItem {

  @JsonProperty()
  title: string;

  @JsonProperty()
  artists: string[];

  @JsonProperty()
  description: string;

  @JsonProperty()
  coverImageUrl?: string;

  @JsonProperty()
  privacy: Privacy = Privacy.AUTO;

  @JsonProperty()
  shareScope?: ShareScope;

  constructor(id: string, creator: string, created: number, title?: string) {
    super(id, creator, created);
    this.title = title || this.defaultTitle();
  }

  checkPrivacy(): boolean {
    if (this.privacy === Privacy.PUBLIC || this.privacy === Privacy.AUTO) {
      return true;
    }
    const memberAuth = getMemberAuth();
    if (!memberAuth) {
      return false;
    }
    if (this.privacy === Privacy.MEMBERS) {
      return true;
    }
    if (this.creator === memberAuth.getMemberId()) {
      return true;
    }
    return this.checkScope();
  }

  private checkScope() {
    return false;
  }

  protected abstract defaultTitle(): string;
}

@JsonObject()
export class Track extends BaseJam {

  @JsonProperty()
  url: string;

  @JsonProperty()
  albumId?: string;

  @JsonProperty()
  duration: number = 0;

  metadata: IAudioMetadata;

  static createNew(url?: string) {
    return new Track(md5_uuid(), getMemberAuth().getMemberId(), Date.now(), url);
  }

  static from(obj: any): Track {
    return JSON_OBJECT.deserializeObject(obj, Track);
  }

  constructor(id: string, creator: string, created: number, url: string) {
    super(id, creator, created);
    this.url = url;
  }

  protected defaultTitle(): string {
    return "Untitled track";
  }

  cloneTrack(): Track {
    return JSON_OBJECT.deserializeObject(JSON_OBJECT.serializeObject(this), Track);
  }
}

export class TrackDraft extends Track {
  blob: Blob;
  coverImageBlob: Blob;

  static createNew(url?: string) {
    console.log("ii: " + getMemberAuth());
    return new TrackDraft(md5_uuid(), getMemberAuth().getMemberId(), Date.now(), url);
  }

  cloneTrack(): TrackDraft {
    const clone = JSON_OBJECT.deserializeObject(JSON_OBJECT.serializeObject(this), TrackDraft);
    clone.blob = this.blob;
    clone.coverImageBlob = this.coverImageBlob;
    return clone;
  }
}

export function TrackSubtitle(track: Track): string {
  if (track.artists) {
    return track.artists.join(", ");
  }
  if (track.description && track.description.length < 20) {
    return track.description;
  }
  if (track.member) {
    return UserDisplayName(track.member.user);
  }
  if (track.created) {
    return TrackTimeToString(track.created);
  }
  return null;
}

export function TrackTimeToString(time: number) {
  return new Date(time).toLocaleString("en-us", {
    dateStyle: "medium",
    timeStyle: "short"
  })
}

export class Tracks extends BaseListItemsLoader<Track> {

  private static instance;
  private static readonly memberInstances = new Map<string, Tracks>();

  static getInstance(): Tracks {
    if (!this.instance) {
      this.instance = new Tracks();
    }
    return this.instance;
  }

  static getMemberInstance(memberId: string): Tracks {
    let instance = this.memberInstances.get(memberId);
    if (!instance) {
      instance = new Tracks(memberId);
      this.memberInstances.set(memberId, instance);
    }
    return instance;
  }

  constructor(private readonly memberId?: string) {
    super({shared: Boolean(memberId)});
  }

  protected basePath(): string {
    return "tracks" + (this.memberId ? "/" + this.memberId : "");
  }

  protected deserializeItem(value: any): Track {
    return JSON_OBJECT.deserializeObject(value, Track);
  }

  protected serializeItem(item: Track): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: Track, item2: Track): number {
    // Reverse chronological
    return item2.created - item1.created;
  }
}

@JsonObject()
export class Album extends BaseJam {

  private tracks: Track[];

  static createNew(title: string): Album {
    return new Album(md5_uuid(), getMemberAuth().getMemberId(), Date.now(), title);
  }

  constructor(id: string, creator: string, created: number, title: string) {
    super(id, creator, created, title);
  }

  protected defaultTitle(): string {
    return "New album";
  }
}

export class Albums extends BaseListItemsLoader<Album> {

  private static instance;
  private static readonly memberInstances = new Map<string, Albums>();

  static getInstance(): Albums {
    if (!this.instance) {
      this.instance = new Albums();
    }
    return this.instance;
  }

  static getMemberInstance(memberId: string): Albums {
    let instance = this.memberInstances.get(memberId);
    if (!instance) {
      instance = new Albums(memberId);
      this.memberInstances.set(memberId, instance);
    }
    return instance;
  }

  constructor(private readonly memberId?: string) {
    super({shared: Boolean(memberId)});
  }

  protected basePath(): string {
    return "albums" + (this.memberId ? "/" + this.memberId : "");
  }

  protected deserializeItem(value: any): Album {
    return JSON_OBJECT.deserializeObject(value, Album);
  }

  protected serializeItem(item: Album): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: Album, item2: Album): number {
    // Reverse chronological
    return item2.created - item1.created;
  }
}

@JsonObject()
export class Playlist extends BaseJam {

  @JsonProperty()
  trackIds: string[]

  private tracks: Track[];

  static createNew(title: string): Playlist {
    return new Playlist(md5_uuid(), getMemberAuth().getMemberId(), Date.now(), title);
  }

  constructor(id: string, creator: string, created: number, title: string) {
    super(id, creator, created, title);
  }

  protected defaultTitle(): string {
    return "New playlist";
  }

  clonePlaylist(): Playlist {
    return JSON_OBJECT.deserializeObject(JSON_OBJECT.serializeObject(this), Playlist);
  }

  async onAfterItemDeserialized(): Promise<void> {
    const tracks: Track[] = [];
    if (this.trackIds) {
      for (const id of this.trackIds) {
        tracks.push(await Tracks.getMemberInstance(this.creator).getOrLoadItem(id));
      }
    }
    this.tracks = tracks;
  }

  getTracks(): Track[] {
    return [...(this.tracks || [])];
  }

  getTrackCoverUrls(): string[] {
    return [];
  }
}

export class Playlists extends BaseListItemsLoader<Playlist> {

  private static instance;
  private static readonly memberInstances = new Map<string, Playlists>();

  static getInstance(): Playlists {
    if (!this.instance) {
      this.instance = new Playlists();
    }
    return this.instance;
  }

  static getMemberInstance(memberId: string): Playlists {
    let instance = this.memberInstances.get(memberId);
    if (!instance) {
      instance = new Playlists(memberId);
      this.memberInstances.set(memberId, instance);
    }
    return instance;
  }

  constructor(private readonly memberId?: string) {
    super({shared: Boolean(memberId)});
  }

  protected basePath(): string {
    return "playlists" + (this.memberId ? "/" + this.memberId : "");
  }

  protected deserializeItem(value: any): Playlist {
    return JSON_OBJECT.deserializeObject(value, Playlist);
  }

  protected serializeItem(item: Playlist): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: Playlist, item2: Playlist): number {
    // Reverse chronological
    return item2.created - item1.created;
  }
}

export interface QueueListener {
  onQueueUpdated(queue: Queue, reset?: boolean): void;
}

export class Queue {

  private static sInstance = new Queue();

  static getInstance(): Queue {
    return this.sInstance;
  }

  private tracks: Track[] = [];
  private listeners: QueueListener[] = [];

  private constructor() {
  }

  addListener(listener: QueueListener) {
    this.listeners = [...this.listeners, listener];
  }

  removeListener(listener: QueueListener) {
    const index = this.listeners.findIndex(item => item === listener);
    if (index >= 0) {
      this.listeners.splice(index, 1);
    }
  }

  private notifyListeners(reset?: boolean) {
    this.listeners.forEach(listener => listener.onQueueUpdated(this, reset));
  }

  shuffle() {
    if (!this.tracks?.length) {
      return;
    }
    const newTracks: Track[] = [];
    let array = Array.from(new Array(this.tracks.length).keys());
    while (array.length > 0) {
      let index = Math.floor(Math.random() * array.length);
      newTracks.push(this.tracks[array[index]]);
      array.splice(index, 1);
    }
    this.tracks = newTracks;
    this.notifyListeners(true);
  }

  setTracks(tracks: Track[]) {
    this.tracks = tracks;
    this.notifyListeners(true);
  }

  addTrack(track: Track) {
    this.tracks = [...this.tracks, track];
    this.notifyListeners();
  }

  getTracks(): Track[] {
    return this.tracks;
  }
}

export enum EmbedType {
  NONE = "none",
  PLAYLIST = "playlist",
}

export type EmbedThemeOptions = {
  mode: PaletteMode,
  white: string,
  black: string,
  primary: string,
  secondary: string,
  bg1: string,
  bg2: string,
}

export const COLOR_YELLOW = "#ff0";
export const COLOR_CYAN = "#0ff";
export const COLOR_MAGENTA = "#f0f";

export const BOLT_EMBED_THEME_OPTIONS: EmbedThemeOptions = {
  mode: "light",
  white: "#eee",
  black: "#333",
  primary: COLOR_CYAN,
  secondary: COLOR_YELLOW,
  bg1: "#999",
  bg2: "#666",
}

export const LIGHT_EMBED_THEME_OPTIONS: EmbedThemeOptions = {
  mode: "light",
  white: "#fff",
  black: "#111",
  primary: "#111",
  secondary: "#099",
  bg1: "#fff",
  bg2: "#ccc",
}

@JsonObject()
export class EmbedConfig {

  @JsonProperty()
  readonly type: EmbedType;

  @JsonProperty()
  themeOptions?: EmbedThemeOptions;

  @JsonProperty()
  memberId?: string;

  @JsonProperty()
  playlistId?: string;

  constructor(type: EmbedType) {
    this.type = type;
  }
}