import {ReactElement} from "react";
import {Track} from "./types";

export function TrackImage(props: { track: Track, style?: any }): ReactElement {
  return <img
    src={props.track?.coverImageUrl || "/images/default_track.svg"}
    style={{
      width: "100%",
      aspectRatio: 1,
      verticalAlign: "top",
      objectFit: "cover", ...props.style
    }}/>;
}