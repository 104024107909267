import React, {Component, ReactElement} from "react";
import {DIVIDER, DIVIDER_BLACK, PD_MD, PD_XLG, PD_XLG2, PD_XSM, SZ_JUMBO, SZ_SM} from "@/shared/dimens";
import {ButtonBase, Grid, IconButton, LinearProgress, Typography, useMediaQuery, useTheme} from "@mui/material";
import {
  StyledBoxColumn,
  StyledBoxRow,
  StyledContainer,
  StyledHeadingVariant,
  StyledSpan
} from "@/shared/StyledComponents";
import {
  ExpandMoreOutlined,
  PauseOutlined,
  PlayArrowOutlined,
  PlayCircleOutlineOutlined,
  RepeatOneOutlined,
  RepeatOnOutlined,
  RepeatOutlined,
  ShuffleOutlined,
  SkipNextOutlined,
  SkipPreviousOutlined,
  StopCircleOutlined
} from "@mui/icons-material";
import {TrackImage} from "./TrackImage";
import {Queue, QueueListener, Track} from "./types";
import {SelectTrack} from "./SelectTrack";
import Markdown from "react-markdown";
import {BaseApp} from "@/shared/BaseApp";

export enum AudioPlayerDisplay {
  MINI = "mini",
  FULL = "full",
  EMBED = "embed",
}

enum Repeat {
  NONE,
  ALL,
  ONE,
}

const REPEAT = [Repeat.NONE, Repeat.ALL, Repeat.ONE];

function formatTime(time: number): string {
  time = Math.floor(time);
  const minutes = Math.floor(time / 60).toString();
  const seconds = (time % 60).toString();
  return minutes.padStart(2, "0") + ":" + seconds.padStart(2, "0");
}

function AudioPlayerEmbed(props: {
  audio: HTMLAudioElement,
  track: Track,
  queue: Track[],
  playing: boolean,
  currentTime: number,
  currentPosition: number,
  repeatIndex: number,
  setRepeatIndex: (repeatIndex: number) => void,
  skipPrevious: () => void,
  skipNext: () => void,
  shuffle: () => void,
  playIndex: number,
  setPlayIndex: (index: number) => void,
  setDisplay: (display: AudioPlayerDisplay) => void,
}): ReactElement {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const audio = props.audio;
  let RepeatIconType;
  switch (REPEAT[props.repeatIndex]) {
    default:
    case Repeat.NONE:
      RepeatIconType = RepeatOutlined;
      break;
    case Repeat.ALL:
      RepeatIconType = RepeatOnOutlined;
      break;
    case Repeat.ONE:
      RepeatIconType = RepeatOneOutlined;
      break;
  }
  // const title = this.state.playing
  //   ? this.state.currentTrack.title || "[Untitled track]"
  //   : this.state.playlist?.title || "No audio found"
  // const coverImageUrl = this.state.playing
  //   ? this.state.currentTrack.coverImageUrl || "/images/default_track.svg"
  //   : this.state.playlist?.coverImageUrl || "/images/default_playlist.svg"
  // return <StyledBoxColumn style={{border: DIVIDER, background: this.theme.palette.background.default}}>
  //   <StyledBoxRow style={{
  //     boxSizing: "border-box",
  //     flexShrink: 0,
  //     padding: PD_MD,
  //     gap: PD_XLG,
  //   }}>
  //     <div style={{width: 192, height: 192, border: DIVIDER_BLACK}}>
  //       <img src={coverImageUrl} style={{verticalAlign: "top", width: "100%", height: "100%", objectFit: "cover"}}/>
  //     </div>
  //     <StyledBoxColumn style={{position: "relative", flexGrow: 1}}>
  //       <ButtonBase href={"https://jambam.net"} style={{position: "absolute", zIndex: 1000, right: 0, top: 0}}>
  //         <img src={"/stamp.png"} style={{width: 72, filter: "grayscale(1)"}}/>
  //       </ButtonBase>
  //       <StyledBoxColumn style={{height: 0, flexGrow: 1}}>
  //         <Typography variant="h4">{title}</Typography>
  //       </StyledBoxColumn>
  //       <StyledBoxColumn style={{height: 0, flexGrow: 1}}>
  //
  //       </StyledBoxColumn>
  //     </StyledBoxColumn>
  //   </StyledBoxRow>
  // </StyledBoxColumn>;
  //
  return <div style={{
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    flexShrink: 0,
    padding: PD_XLG,
    border: DIVIDER,
    position: "relative",
  }}>
    <StyledBoxRow style={{gap: 4, padding: 4, position: "absolute", top: 0, right: 0}}>
      <Typography variant="caption">Powered by</Typography>
      <ButtonBase href={"https://jambam.net"} target="_blank">
        <img src={"/stamp.png"} style={{width: 72, filter: "grayscale(1)"}}/>
      </ButtonBase>
    </StyledBoxRow>
    <div style={{
      display: "flex",
      flexDirection: md ? "row" : "column",
      gap: PD_XLG2,
    }}>
      <TrackImage track={props.track}
                  style={{width: SZ_JUMBO, flexShrink: 0, alignSelf: "center", border: DIVIDER_BLACK}}/>
      <StyledBoxColumn style={{flexGrow: 1,}}>
        <StyledSpan/>
        <StyledHeadingVariant default_={2} style={{textAlign: md?"start":"center"}}>{props.track.title}</StyledHeadingVariant>
        {/*<Typography component="div"><Markdown>{props.track.description}</Markdown></Typography>*/}
        <StyledBoxRow style={{
          alignItems: "center",
          height: SZ_SM,
          marginBottom: -24,
        }}>
          {props.currentPosition ? <>
              <LinearProgress style={{flexGrow: 1}} variant="determinate" value={props.currentPosition * 100}/>
              <Typography>{formatTime(props.currentTime)}</Typography>
            </>
            : null}
        </StyledBoxRow>
        <StyledBoxRow style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
          <IconButton color="primary" onClick={() => props.shuffle()}>
            <ShuffleOutlined style={{width: 32, height: 32}}/>
          </IconButton>
          <IconButton color="primary"
                      onClick={() => props.skipPrevious()}>
            <SkipPreviousOutlined style={{width: 48, height: 48}}/>
          </IconButton>
          <IconButton color="primary" onClick={() => audio.paused ? audio.play() : audio.pause()}>
            {audio.paused ? <PlayArrowOutlined style={{width: 72, height: 72}}/> :
              <PauseOutlined style={{width: 72, height: 72}}/>}
          </IconButton>
          <IconButton color="primary"
                      onClick={() => props.skipNext()}>
            <SkipNextOutlined style={{width: 48, height: 48}}/>
          </IconButton>
          <IconButton color="primary"
                      onClick={() => props.setRepeatIndex((props.repeatIndex + 1) % REPEAT.length)}>
            <RepeatIconType style={{width: 32, height: 32}}/>
          </IconButton>
        </StyledBoxRow>
      </StyledBoxColumn>
    </div>
    <SelectTrack
      selectedIndex={props.playIndex}
      tracks={props.queue} onTrackSelected={(track, index) => props.setPlayIndex(index)}/>
  </div>;
}

function AudioPlayerFull(props: {
  audio: HTMLAudioElement,
  track: Track,
  queue: Track[],
  playing: boolean,
  currentTime: number,
  currentPosition: number,
  repeatIndex: number,
  setRepeatIndex: (repeatIndex: number) => void,
  skipPrevious: () => void,
  skipNext: () => void,
  shuffle: () => void,
  playIndex: number,
  setPlayIndex: (index: number) => void,
  setDisplay: (display: AudioPlayerDisplay) => void,
}): ReactElement {
  const theme = useTheme();
  const audio = props.audio;
  let RepeatIconType;
  switch (REPEAT[props.repeatIndex]) {
    default:
    case Repeat.NONE:
      RepeatIconType = RepeatOutlined;
      break;
    case Repeat.ALL:
      RepeatIconType = RepeatOnOutlined;
      break;
    case Repeat.ONE:
      RepeatIconType = RepeatOneOutlined;
      break;
  }
  return <div style={{
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    flexShrink: 0,
    height: "100vh",
    overflowY: "scroll",
    padding: PD_XSM,
  }}>
    <IconButton
      onClick={() => props.setDisplay(AudioPlayerDisplay.MINI)}
      style={{alignSelf: "center", position: "fixed", top: 12, background: theme.palette.background.paper}}>
      <ExpandMoreOutlined/>
    </IconButton>
    <StyledContainer style={{marginTop: 24}}>
      <TrackImage track={props.track} style={{width: SZ_JUMBO, alignSelf: "center", border: DIVIDER_BLACK}}/>
      <StyledBoxColumn style={{}}>
        <StyledHeadingVariant default_={3} style={{textAlign: "center"}}>{props.track.title}</StyledHeadingVariant>
        <Typography component="div"
                    style={{textAlign: "center"}}><Markdown>{props.track.description}</Markdown></Typography>
      </StyledBoxColumn>
      <StyledBoxRow style={{
        alignItems: "center",
        height: SZ_SM,
        marginBottom: -24,
      }}>
        {props.currentPosition ? <>
            <LinearProgress style={{flexGrow: 1}} variant="determinate" value={props.currentPosition * 100}/>
            <Typography>{formatTime(props.currentTime)}</Typography>
          </>
          : null}
      </StyledBoxRow>
      <StyledBoxRow style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
        <IconButton color="primary" onClick={() => props.shuffle()}>
          <ShuffleOutlined style={{width: 32, height: 32}}/>
        </IconButton>
        <IconButton color="primary"
                    onClick={() => props.skipPrevious()}>
          <SkipPreviousOutlined style={{width: 48, height: 48}}/>
        </IconButton>
        <IconButton color="primary" onClick={() => audio.paused ? audio.play() : audio.pause()}>
          {audio.paused ? <PlayArrowOutlined style={{width: 72, height: 72}}/> :
            <PauseOutlined style={{width: 72, height: 72}}/>}
        </IconButton>
        <IconButton color="primary"
                    onClick={() => props.skipNext()}>
          <SkipNextOutlined style={{width: 48, height: 48}}/>
        </IconButton>
        <IconButton color="primary"
                    onClick={() => props.setRepeatIndex((props.repeatIndex + 1) % REPEAT.length)}>
          <RepeatIconType style={{width: 32, height: 32}}/>
        </IconButton>
      </StyledBoxRow>
      <SelectTrack
        selectedIndex={props.playIndex}
        tracks={props.queue} onTrackSelected={(track, index) => props.setPlayIndex(index)}/>
    </StyledContainer>
  </div>;
}

function AudioPlayerMini(props: {
  audio: HTMLAudioElement,
  track: Track,
  playing: boolean,
  setDisplay: (display: AudioPlayerDisplay) => void
}): ReactElement {
  const audio = props.audio;
  const hasStation = Boolean(props.track);
  return <div style={{
    boxSizing: "border-box",
    flexShrink: 0,
    height: 72,
    padding: PD_XSM,
  }}>
    <Grid container style={{width: "100%"}} spacing={1}>
      <Grid item xs={12} md={9}>
        <StyledBoxRow
          style={{
            boxSizing: "border-box",
            width: "100%",
            height: "100%",
            gap: PD_MD,
            alignItems: "center",
            paddingLeft: PD_MD,
          }}>
          <ButtonBase
            disabled={!hasStation}
            style={{opacity: hasStation ? 1 : .5}}
            onClick={() => {
              if (props.playing) {
                audio.pause();
              } else {
                audio.play();
              }
            }}>
            {props.playing
              ? <StopCircleOutlined style={{width: 40, height: 40}}/>
              : <PlayCircleOutlineOutlined style={{width: 40, height: 40}}/>}
          </ButtonBase>
          <ButtonBase
            onClick={() => props.setDisplay?.(AudioPlayerDisplay.FULL)}
            style={{flexGrow: 1}}>
            <StyledBoxRow style={{flexGrow: 1, position: "relative", alignItems: "center"}}>
              <div style={{
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                background: "black",
                opacity: 0.1,
              }}/>
              <TrackImage track={props.track} style={{width: 56, height: 56}}/>
              <Typography
                style={{fontSize: "120%", fontWeight: "bold"}}>{props.track?.title}
              </Typography>
              <StyledSpan/>
            </StyledBoxRow>
          </ButtonBase>
        </StyledBoxRow>
      </Grid>
      <Grid item xs={0} md={3}>
        <div style={{
          display: "flex",
          height: "100%",
          flexGrow: 1,
          alignItems: "center",
        }}>
          <div style={{
            height: 44,
            alignSelf: "center",
            flexGrow: 1,
            background: "url(/images/dot.png) repeat"
          }}/>
        </div>
      </Grid>
    </Grid>
  </div>;
}

export type AudioPlayerProps = {
  audio: HTMLAudioElement,
  initialDisplay?: AudioPlayerDisplay,
}

type AudioPlayerItem = {
  track: Track,
  index: number,
}

type AudioPlayerState = {
  display: AudioPlayerDisplay,
  playing: boolean,
  currentTime: number,
  currentPosition: number,
  shouldPlayIndex: number,
  playIndex: number,
  items: AudioPlayerItem[],
  repeatIndex: number,
}

export class AudioPlayer extends Component<AudioPlayerProps, AudioPlayerState> implements QueueListener {

  private readonly theme = BaseApp.CONTEXT.getAppConfig().theme;
  private readonly queue = Queue.getInstance();

  constructor(props: AudioPlayerProps, context: any) {
    super(props, context);
    this.state = {
      display: props.initialDisplay || AudioPlayerDisplay.MINI,
      playing: false,
      currentTime: -1,
      currentPosition: -1,
      shouldPlayIndex: -1,
      playIndex: -1,
      items: [],
      repeatIndex: 0,
    }
  }

  componentDidMount() {
    const audio = this.props.audio;
    audio.addEventListener("play", () => {
      this.setState({
        playing: true,
      });
    });
    audio.addEventListener("pause", () => {
      this.setState({
        playing: false,
      });
    });
    audio.addEventListener("ended", () => {
      switch (REPEAT[this.state.repeatIndex]) {
        case Repeat.NONE:
          if (this.state.playIndex === this.state.items.length - 1) {
            return;
          }
          this.skipNext();
          break;
        case Repeat.ALL:
          this.skipNext();
          break;
        case Repeat.ONE:
          audio.fastSeek(0);
          break;
      }
    });
    if (this.queue.getTracks()?.length > 0) {
      this.onQueueUpdated(this.queue, true);
    }
    this.queue.addListener(this);
    this.updateTrack();
  }

  private updateTimerId: any;

  componentDidUpdate(prevProps: Readonly<AudioPlayerProps>, prevState: Readonly<AudioPlayerState>, snapshot?: any) {
    const audio = this.props.audio;
    if (this.state.items?.length > 0 && prevState.shouldPlayIndex < 0 && this.state.display !== AudioPlayerDisplay.EMBED) {
      this.setState({
        shouldPlayIndex: 0,
      });
    } else if (this.state.shouldPlayIndex !== this.state.playIndex) {
      audio.pause();
      this.setState({
        playIndex: this.state.shouldPlayIndex,
      });
    } else if (prevState.playIndex !== this.state.playIndex) {
      this.updateTrack();
    } else if (this.state.playing !== prevState.playing) {
      clearInterval(this.updateTimerId);
      if (this.state.playing) {
        this.updateTimerId = setInterval(() => {
          const currentTime = audio.currentTime;
          const currentPosition = audio.duration > 0 ? Math.min(1, Math.max(0, currentTime / audio.duration)) : 1;
          this.setState({
            currentTime: currentTime,
            currentPosition: currentPosition,
          });
        }, 500);
      }
    }
  }

  componentWillUnmount() {
    this.queue.removeListener(this);
  }

  private updateTrack(): void {
    const audio = this.props.audio;
    const src = this.getPlaying()?.track.url;
    if (src) {
      audio.src = src;
      if (this.state.playing || this.state.display !== AudioPlayerDisplay.EMBED) {
        audio.play();
      }
    } else {
      audio.pause();
    }
  }

  onQueueUpdated(queue: Queue, reset?: boolean): void {
    this.setState({
      items: queue.getTracks()?.map((track, index) => {
        return {
          track: track,
          index: index,
        };
      }),
      shouldPlayIndex: Boolean(reset) ? 0 : this.state.shouldPlayIndex,
      playIndex: Boolean(reset) ? -1 : this.state.playIndex,
    });
  }

  private getPlaying(): AudioPlayerItem {
    const items = this.state.items;
    const playIndex = this.state.playIndex;
    if (!items?.length || playIndex < 0) {
      return null;
    }
    return items[playIndex];
  }

  private renderInFixedContainerAtBottom(...children: ReactElement[]): ReactElement {
    return <div style={{
      zIndex: 10000,
      position: "fixed",
      background: this.theme.palette.background.paper,
      width: "100%",
      bottom: 0,
    }}>
      {children}
    </div>;
  }

  render(): ReactElement {
    if (!this.state.items?.[0]) {
      return null;
    }
    const audio = this.props.audio;
    const track = this.getPlaying()?.track;
    switch (this.state.display) {
      case AudioPlayerDisplay.EMBED:
        return <div style={{
          background: this.theme.palette.background.paper,
          width: "100%",
        }}>
          <AudioPlayerEmbed
            audio={audio}
            track={track || this.state.items?.[0]?.track}
            queue={this.queue.getTracks()}
            playing={this.state.playing}
            currentPosition={this.state.currentPosition}
            currentTime={this.state.currentTime}
            repeatIndex={this.state.repeatIndex}
            setRepeatIndex={repeatIndex => this.setState({repeatIndex: repeatIndex})}
            skipPrevious={() => this.skipPrevious()}
            skipNext={() => this.skipNext()}
            shuffle={() => this.shuffle()}
            playIndex={this.state.playIndex}
            setPlayIndex={index => this.setState({shouldPlayIndex: index})}
            setDisplay={display => this.setState({display: display})}/>
        </div>;
      case AudioPlayerDisplay.FULL:
        return track
          ? this.renderInFixedContainerAtBottom(<AudioPlayerFull
            audio={audio}
            track={track}
            queue={this.queue.getTracks()}
            playing={this.state.playing}
            currentPosition={this.state.currentPosition}
            currentTime={this.state.currentTime}
            repeatIndex={this.state.repeatIndex}
            setRepeatIndex={repeatIndex => this.setState({repeatIndex: repeatIndex})}
            skipPrevious={() => this.skipPrevious()}
            skipNext={() => this.skipNext()}
            shuffle={() => this.shuffle()}
            playIndex={this.state.playIndex}
            setPlayIndex={index => this.setState({shouldPlayIndex: index})}
            setDisplay={display => this.setState({display: display})}/>)
          : null;
      default:
      case AudioPlayerDisplay.MINI:
        return track
          ? this.renderInFixedContainerAtBottom(<AudioPlayerMini
            audio={audio}
            track={track}
            playing={this.state.playing}
            setDisplay={display => this.setState({display: display})}/>)
          : null;
    }
  }

  private skipNext() {
    const items = this.state.items;
    const playIndex = this.state.playIndex;
    this.setState({shouldPlayIndex: (playIndex === items.length - 1) ? 0 : playIndex + 1});
  }

  private skipPrevious() {
    const items = this.state.items;
    const playIndex = this.state.playIndex;
    this.setState({shouldPlayIndex: (playIndex === 0) ? items.length - 1 : playIndex - 1});
  }

  private shuffle() {
    this.queue.shuffle();
  }
}